import api from '../base';
import { Address } from '../address/addressApiTypes';
import { mockCreateOrUpdateAddress, mockGetAddress } from './b2bMock';
import { B2bAddress, mapAddress, mapB2bAddress } from './b2bApiTypes';

const B2B_PRODUCTS_BASE_PATH = 'management/b2b-products';
const B2B_PARTNERS_BASE_PATH = 'management/b2b-partners';

export async function importB2bPrices(file: FormData): Promise<void> {
  const response = await api.post<void>(`${B2B_PRODUCTS_BASE_PATH}/import`, file, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

api.mock
  .onPost(/management\/b2b-products\/import/)
  .reply(200);

export async function getB2bAddress(signal?: AbortSignal): Promise<Address> {
  const response = await api.get<B2bAddress>(
    `${B2B_PARTNERS_BASE_PATH}/address`,
    { signal },
  );
  return mapAddress(response.data);
}

api.mock
  .onGet(/management\/b2b-partners\/address/)
  .reply(200, mockGetAddress);

export async function createOrUpdateB2bAddress(
  address: Omit<Address, 'id'>,
  signal?: AbortSignal,
): Promise<Address> {
  const response = await api.post<B2bAddress>(
    `${B2B_PARTNERS_BASE_PATH}/address`,
    mapB2bAddress(address),
    { signal },
  );
  return mapAddress(response.data);
}

api.mock
  .onPost(/management\/b2b-partners\/address/)
  .reply(200, mockCreateOrUpdateAddress);
