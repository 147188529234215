import api from '../base';
import { Address, AddressType } from './addressApiTypes';
import { mockGetAddress } from './addressMock';

const BASE_PATH = 'management/addresses';

export async function getAddress(
  orderId: number,
  addressType: AddressType,
  signal?: AbortSignal,
): Promise<Address> {
  return api.get<Address>(`${BASE_PATH}/${orderId}/${addressType}`, {
    signal,
  })
    .then(response => response.data);
}

api.mock
  .onGet(/management\/addresses\/\d+\/.+/)
  .reply(200, mockGetAddress);

export async function updateAddress(
  orderId: number,
  addressType: AddressType,
  address: Omit<Address, 'id'>,
  signal?: AbortSignal,
): Promise<null> {
  return api.put<null>(`${BASE_PATH}/${orderId}/${addressType}`, address, {
    signal,
  })
    .then(response => response.data);
}

api.mock
  .onPut(/management\/addresses\/\d+\/.+/)
  .reply(204);
