import React, {
  forwardRef, Ref, useEffect, useImperativeHandle, useReducer, useRef,
} from 'react';
import axios from 'axios';
import { Loader, Schema } from 'rsuite';
import { Address } from '../../api/address/addressApiTypes';
import { showSuccessMessage } from '../../utils/message';
import AddressForm from './AddressForm';
import { createOrUpdateB2bAddress, getB2bAddress } from '../../api/b2b/b2b';

const countryCodes = [
  'CZ',
  'SK',
  'PL',
  'HU',
  'HR',
  'SI',
  'DE',
  'AT',
  'ZW',
  'BY',
];

const model = Schema.Model({
  country: Schema.Types.StringType().isOneOf(countryCodes).isRequired(),
});

interface Props {
  onCancel?: () => void;
}

export interface B2bAddressEditRef {
  isValid: () => boolean;
}

type Status = 'ready' | 'loading' | 'saving';

interface State {
  status: Status,
  address?: Address
}

const initialState: State = {
  status: 'ready',
};

const editingKeys: string[] = [
  'name',
  'company',
  'phone',
  'email',
  'addressLine1',
  'addressLine2',
  'city',
  'state',
  'zip',
  'country',
  'registrationNumber',
  'vatNumber',
];

type StatusSetAction = { type: 'status/set', payload: Status };
type AddressSetAction = { type: 'address/set', payload: Address };
type Action = StatusSetAction | AddressSetAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'status/set': {
      return { ...state, status: action.payload };
    }
    case 'address/set': {
      return {
        ...state,
        address: action.payload,
      };
    }
    default:
      return state;
  }
}

function B2bAddressEdit({ onCancel }: Props, ref: Ref<B2bAddressEditRef>): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState);
  const abortControllerRef = useRef<AbortController>(new AbortController());

  useImperativeHandle(ref, () => ({
    isValid: () => !!state.address?.id,
  }));

  async function fetchAddress() {
    if (abortControllerRef.current.signal.aborted) {
      abortControllerRef.current = new AbortController();
    }

    dispatch({ type: 'status/set', payload: 'loading' });

    try {
      const address = await getB2bAddress(abortControllerRef.current.signal);
      dispatch({ type: 'address/set', payload: address });
    } catch (error) {
      if (!axios.isAxiosError(error) || error.response?.status !== 404) {
        throw error;
      }
    } finally {
      dispatch({ type: 'status/set', payload: 'ready' });
    }
  }

  async function saveAddress(address: Address) {
    dispatch({ type: 'status/set', payload: 'saving' });

    try {
      const savedAddress = await createOrUpdateB2bAddress(
        address,
        abortControllerRef.current.signal,
      );
      dispatch({ type: 'address/set', payload: savedAddress });
      showSuccessMessage('Address successfully updated.');
    } finally {
      dispatch({ type: 'status/set', payload: 'ready' });
    }
  }

  useEffect(() => {
    fetchAddress().then();

    return () => {
      abortControllerRef.current?.abort();
    };
  }, []);

  function handleSubmit(address: Address) {
    saveAddress(address).then();
  }

  function handleCancel() {
    onCancel?.();
  }

  if (state.status === 'loading') {
    return <Loader />;
  }

  return (
    <AddressForm
      address={state.address}
      editingKeys={editingKeys}
      model={model}
      layout="horizontal"
      isSubmitting={state.status === 'saving'}
      onSubmit={(address) => handleSubmit(address)}
      onCancel={() => handleCancel()}
    />
  );
}

B2bAddressEdit.defaultProps = {
  onCancel: undefined,
};

// @ts-ignore
export default forwardRef<B2bAddressEditRef, Props>(B2bAddressEdit);
