import React, { useEffect, useReducer, useRef } from 'react';
import { Button, Form, Panel } from 'rsuite';
import FormGroup from 'rsuite/FormGroup';
import styled from '@emotion/styled';
import { OrderForLabelPrint } from '../../api/apiTypes';
import { useStoreState } from '../../store/hooks';
import { updateParcelShopBranchId } from '../../api/orders';
import { showSuccessMessage } from '../../utils/message';
import { ApiGroup } from '../../api/model/ApiGroup';
import { Permission } from '../../store/user/types';

const Container = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

interface Props {
  order: OrderForLabelPrint
}

type Status = 'ready' | 'changing' | 'saving';

interface Edit {
  parcelShopBranchId: string;
}

interface State {
  status: Status;
  edit: Edit;
}

const initialState: State = {
  status: 'ready',
  edit: {
    parcelShopBranchId: '',
  },
};

type StatusSetAction = { type: 'status/set', payload: Status };
type EditSetAction = { type: 'edit/set', payload: Edit };
type Action = StatusSetAction | EditSetAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'status/set': {
      return { ...state, status: action.payload };
    }
    case 'edit/set':
      return { ...state, edit: action.payload };
    default:
      return state;
  }
}

function mapOrderToEdit(order: OrderForLabelPrint): Edit {
  const { parcelShopBranchId } = order;
  return {
    parcelShopBranchId: parcelShopBranchId || '',
  };
}

function buttonTitleForStatus(status: Status): string {
  switch (status) {
    case 'changing':
    case 'saving':
      return 'Save';
    default:
      return 'Change';
  }
}

export default function ParcelShopBranchIdEdit({ order }: Props): JSX.Element | null {
  const currentUser = useStoreState(state => state.user);
  const [state, dispatch] = useReducer(reducer, initialState);
  const abortControllerRef = useRef<AbortController>(new AbortController());

  useEffect(() => {
    dispatch({ type: 'edit/set', payload: mapOrderToEdit(order) });
  }, [order]);

  if (order.apiGroup !== ApiGroup.ALLEGRO) {
    return null;
  }

  if (order.managedByPartner !== false) {
    return null;
  }

  if (!currentUser.hasPermission(Permission.UpdateParcelShopBranchId)) {
    return null;
  }

  async function sendUpdateParcelShopBranchId(): Promise<void> {
    if (abortControllerRef.current.signal.aborted) {
      abortControllerRef.current = new AbortController();
    }

    const { id } = order;
    const { parcelShopBranchId } = state.edit;
    const { signal } = abortControllerRef.current;

    dispatch({ type: 'status/set', payload: 'saving' });

    try {
      await updateParcelShopBranchId(id, parcelShopBranchId, signal);
      showSuccessMessage(`Parcel shop branch id successfully updated to ${parcelShopBranchId}.`);
    } finally {
      dispatch({ type: 'status/set', payload: 'ready' });
    }
  }

  function handleChange(edit: Edit): void {
    dispatch({ type: 'edit/set', payload: edit });
  }

  function handleClick() {
    if (state.status === 'ready') {
      dispatch({ type: 'status/set', payload: 'changing' });
    }

    if (state.status === 'changing') {
      sendUpdateParcelShopBranchId().then();
    }
  }

  return (
    <Container>
      <Panel header="Salesconnect admin" bordered>
        <Form
          formValue={state.edit}
          layout="inline"
          onChange={(edit) => handleChange(edit as Edit)}
        >
          <FormGroup>
            <Form.ControlLabel>Parcel shop branch id</Form.ControlLabel>
            <Form.Control
              name="parcelShopBranchId"
              disabled={state.status !== 'changing'}
            />
          </FormGroup>
          <FormGroup>
            <Button
              appearance="primary"
              loading={state.status === 'saving'}
              onClick={() => handleClick()}
            >
              {buttonTitleForStatus(state.status)}
            </Button>
          </FormGroup>
        </Form>
      </Panel>
    </Container>
  );
}
