import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { getPartnerInfo, savePartnerInfo } from '../../api/partner';
import { Integration, PartnerInfo } from '../../api/apiTypes';
import { useStoreState } from '../../store/hooks';
import { showSuccessMessage } from '../../utils/message';

type Tab = 'mpi' | 'platform' | 'feed';

interface PartnerSetupContext {
  partner: {
    partnerName: string;
    partnerCode: string;
    integration: Integration;
    b2bPricingEnabled: boolean;
  };
  tab: Tab;
  savePartner: (partner: Omit<PartnerInfo, 'partnerCode'>) => void;
}

const Context = createContext<PartnerSetupContext>({
  partner: {
    partnerName: '',
    partnerCode: '',
    integration: Integration.MPI,
    b2bPricingEnabled: false,
  },
  tab: 'mpi',
  savePartner: () => {},
});

function usePartnerSetupContext(): PartnerSetupContext {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('usePartnerSetupContext must be used within a Provider');
  }

  return context;
}

interface ProviderProps {
  children: React.ReactNode;
}

function PartnerSetupProvider({ children }: ProviderProps): React.ReactElement {
  const [partnerInfo, setPartnerInfo] = useState<PartnerInfo | undefined>();
  const { currentUser } = useStoreState(state => state.user);
  const { tab = 'mpi' } = useParams<{ tab: Tab }>();

  async function fetchPartner(): Promise<void> {
    const response = await getPartnerInfo();
    setPartnerInfo(response);
  }

  async function savePartner(partner: Omit<PartnerInfo, 'partnerCode'>): Promise<void> {
    await savePartnerInfo(partner);
    setPartnerInfo((prev) => ({ ...prev, ...partner }));
    showSuccessMessage('Partner successfully saved.');
  }

  const context: PartnerSetupContext = useMemo(
    () => ({
      partner: {
        partnerName: partnerInfo?.partnerName ?? currentUser.partnerName,
        partnerCode: currentUser.partnerCode,
        integration: partnerInfo?.integration ?? Integration.MPI,
        b2bPricingEnabled: partnerInfo?.b2bPricingEnabled ?? false,
      },
      tab,
      savePartner,
    }),
    [
      partnerInfo,
      setPartnerInfo,
      currentUser,
      tab,
      savePartner,
    ],
  );

  useEffect(() => {
    fetchPartner().then();
  }, [currentUser]);

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  );
}

export { PartnerSetupProvider, usePartnerSetupContext };
