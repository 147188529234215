import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import { Modal } from 'rsuite';
import { MdEdit } from 'react-icons/md';
import { OrderListItem } from '../../api/apiTypes';
import { AddressType } from '../../api/address/addressApiTypes';
import OrderAddressEdit from './OrderAddressEdit';

function getAddressType(order: OrderListItem): AddressType {
  return order.crossDockDelivery ? AddressType.LAST_MILE_DELIVERY : AddressType.DELIVERY;
}

interface AddressEditContext {
  order?: OrderListItem;
  setOrder: (order?: OrderListItem) => void;
}

const Context = createContext<AddressEditContext>({
  order: undefined,
  setOrder: () => {
  },
});

function useAddressEditContext() {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useAddressEditContext must be used within a Provider');
  }

  return context;
}

function Window(): JSX.Element {
  const { order, setOrder } = useAddressEditContext();
  const open = !!order;

  function handleClose(): void {
    setOrder(undefined);
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      backdrop
      overflow
    >
      <Modal.Body>
        {order && (
        <OrderAddressEdit
          orderId={order.id}
          addressType={getAddressType(order)}
          onCancel={() => handleClose()}
        />
        )}
      </Modal.Body>
    </Modal>
  );
}

interface ProviderProps {
  children: React.ReactNode;
}

function Provider({ children }: ProviderProps): JSX.Element {
  const [order, setOrder] = useState<OrderListItem | undefined>();

  const context: AddressEditContext = useMemo(
    () => ({
      order,
      setOrder,
    }),
    [order, setOrder],
  );

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  );
}

interface OpenProps {
  order?: OrderListItem;
}

function Open({ order }: OpenProps): JSX.Element {
  const { setOrder } = useAddressEditContext();

  function handleClick(): void {
    setOrder(order);
  }

  return (
    <MdEdit
      onClick={() => handleClick()}
      title="Edit address."
      type="button"
      cursor="pointer"
    />
  );
}

Open.defaultProps = {
  order: undefined,
};

interface Props {
  order?: OrderListItem;
}

export default function AddressEditModal({ order }: Props): JSX.Element {
  return (
    <Provider>
      <Open order={order} />
      <Window />
    </Provider>
  );
}

AddressEditModal.Window = Window;
AddressEditModal.Provider = Provider;
AddressEditModal.Open = Open;
AddressEditModal.defaultProps = {
  order: undefined,
};
