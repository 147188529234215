import React, {
  ReactNode, useEffect, useReducer, useRef,
} from 'react';
import {
  Button,
  ButtonToolbar,
  CheckPicker,
  Form,
  FormInstance,
  Loader,
  Schema,
  SelectPicker,
  Stack,
  Toggle,
} from 'rsuite';
import { useNavigate } from 'react-router-dom';
import { ItemDataType } from 'rsuite/CascadeTree';
import { getTransformations } from '../../api/feed/xmlMorpher';
import { Eshop, getEshops } from '../../api/eshop/eshop';
import { Transformation } from '../../api/feed/xmlMorpherApiTypes';
import { Feed, FeedType, getFeedTypeLabel } from '../../api/feed/feedApiTypes';
import { showSuccessMessage } from '../../utils/message';
import { postFeed } from '../../api/feed/feed';
import { PARTNER_SETUP } from '../../routes/links';

type Status = 'ready' | 'loading' | 'saving';

interface Edit {
  type: FeedType;
  xmlMorpherId: string;
  countryCodes: string[];
  marketplaceCodes: string[];
  enabled: boolean;
}

interface State {
  status: Status;
  transformations: Transformation[];
  eshops: Eshop[];
  edit: Edit;
  transformationNames: ItemDataType[];
  feedTypes: ItemDataType[];
  countryCodes: ItemDataType[];
  marketplaceCodes: ItemDataType[];
}

type StatusSetAction = { type: 'status/set', payload: Status };
type DataFetchedAction = { type: 'data/fetched', payload: [Transformation[], Eshop[]] };
type EditSetAction = { type: 'edit/set', payload: Edit };
type Action = StatusSetAction | DataFetchedAction | EditSetAction;

const initialFeedTypes: ItemDataType[] = Object.values(FeedType)
  .map(feedType => ({ label: getFeedTypeLabel(feedType), value: feedType }));

const initialCountryCodes: ItemDataType[] = ['CZE', 'SVK', 'HUN', 'HRV', 'POL', 'SVN', 'DEU', 'AUT']
  .map(countryCode => ({ label: countryCode, value: countryCode }));

const initialState: State = {
  status: 'ready',
  transformations: [],
  eshops: [],
  edit: {
    type: '' as FeedType,
    xmlMorpherId: '',
    countryCodes: [],
    marketplaceCodes: [],
    enabled: true,
  },
  transformationNames: [],
  feedTypes: initialFeedTypes,
  countryCodes: initialCountryCodes,
  marketplaceCodes: [],
};

const model = Schema.Model({
  xmlMorpherId: Schema.Types.NumberType().isRequired('Transformation name is required.'),
  type: Schema.Types.StringType().isRequired('Feed type is required.'),
});

function mapEdit(edit: Edit): Omit<Feed, 'id' | 'url'> {
  return { ...edit, xmlMorpherId: +edit.xmlMorpherId };
}

function mapTransformations(transformations: Transformation[]): ItemDataType[] {
  return transformations.map((transformation) => ({
    label: transformation.name,
    value: transformation.id,
  }));
}

function mapEshops(eshops: Eshop[]): ItemDataType[] {
  return eshops.map((eshop) => ({ label: eshop.code, value: eshop.code }));
}

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'status/set': {
      return { ...state, status: action.payload };
    }
    case 'data/fetched': {
      const [transformations, eshops] = action.payload;
      return {
        ...state,
        transformations,
        eshops,
        transformationNames: mapTransformations(transformations),
        marketplaceCodes: mapEshops(eshops),
      };
    }
    case 'edit/set': {
      return { ...state, edit: action.payload };
    }
    default:
      return state;
  }
}

function Container({ children }: { children: ReactNode }) {
  return (
    <div className="m-10" style={{ marginBottom: 150 }}>
      <Stack
        direction="column"
        alignItems="stretch"
        spacing={15}
        justifyContent="flex-start"
      >
        <h4>Add new feed</h4>
        {children}
      </Stack>
    </div>
  );
}

export default function PartnerSetupFeedAdd() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const formRef = useRef<FormInstance>(null);
  const navigate = useNavigate();

  function navigateBack() {
    navigate(`${PARTNER_SETUP}/feed`, { replace: true });
  }

  async function fetchData() {
    dispatch({ type: 'status/set', payload: 'loading' });

    try {
      const data = await Promise.all([
        getTransformations(),
        getEshops().then(response => response.data),
      ]);

      dispatch({ type: 'data/fetched', payload: data });
    } finally {
      dispatch({ type: 'status/set', payload: 'ready' });
    }
  }

  async function saveEdit() {
    dispatch({ type: 'status/set', payload: 'saving' });

    try {
      const feed = mapEdit(state.edit);
      await postFeed(feed);

      showSuccessMessage('New feed successfully added.');
      navigateBack();
    } finally {
      dispatch({ type: 'status/set', payload: 'ready' });
    }
  }

  function handleChange(edit: Edit) {
    dispatch({ type: 'edit/set', payload: edit });
  }

  function handleSave() {
    if (!formRef.current?.check()) {
      return;
    }

    saveEdit().then();
  }

  function handleCancel() {
    navigateBack();
  }

  useEffect(() => {
    fetchData().then();
  }, []);

  if (state.status === 'loading') {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  return (
    <Container>
      <Form
        layout="horizontal"
        formValue={state.edit}
        model={model}
        onChange={(edit) => handleChange(edit as Edit)}
        ref={formRef}
        fluid
      >
        <Form.Group>
          <Form.ControlLabel>Transformation name</Form.ControlLabel>
          <Form.Control
            accepter={SelectPicker}
            name="xmlMorpherId"
            data={state.transformationNames}
            value={state.edit.xmlMorpherId}
            searchable={false}
            cleanable={false}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Feed type</Form.ControlLabel>
          <Form.Control
            accepter={SelectPicker}
            name="type"
            data={state.feedTypes}
            value={state.edit.type}
            searchable={false}
            cleanable={false}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Country</Form.ControlLabel>
          <Form.Control
            accepter={CheckPicker}
            name="countryCodes"
            data={state.countryCodes}
            value={state.edit.countryCodes}
            searchable={false}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Marketplace</Form.ControlLabel>
          <Form.Control
            accepter={CheckPicker}
            name="marketplaceCodes"
            data={state.marketplaceCodes}
            value={state.edit.marketplaceCodes}
            searchable={false}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Enabled</Form.ControlLabel>
          <Form.Control
            accepter={Toggle}
            name="enabled"
            value={state.edit.enabled}
          />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              appearance="primary"
              onClick={() => handleSave()}
              type="submit"
              loading={state.status === 'saving'}
            >
              Save
            </Button>
            <Button
              appearance="ghost"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </Container>
  );
}
