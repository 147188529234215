import api from './base';
import { IntegrationInfo } from './apiTypes';

export async function getIntegrationInfo(): Promise<IntegrationInfo> {
  return api.get<IntegrationInfo>('/management/integration/info').then(response => response.data);
}

const mockResponse: IntegrationInfo = {
  baseLinkerToken: 'asdfasdfasdf',
  webEdiGln: 'GLN123456789',
};

api.mock.onGet('/management/integration/info').reply(200, mockResponse);

export async function saveIntegrationInfo(info: IntegrationInfo): Promise<string> {
  return api.post<string>('/management/integration/info', info).then(response => response.data);
}

api.mock.onPost('/management/integration/info').reply(202);
