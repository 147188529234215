import { Address } from './addressApiTypes';

// eslint-disable-next-line import/prefer-default-export
export const mockGetAddress: Address = {
  id: 1,
  addressLine1: 'U garáží 1',
  city: 'Wroclaw',
  country: 'PL',
  email: 'info@mall.pl',
  name: 'Józef Nowak',
  phone: '+48123456789',
  zip: '50-155',
};
