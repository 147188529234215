export interface Address {
  id: number;
  originalId?: string;
  name?: string;
  company?: string;
  phone?: string;
  email?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  note?: string;
  registrationNumber?: string;
  vatNumber?: string;
}

export enum AddressType {
  DELIVERY = 'DELIVERY',
  BILLING = 'BILLING',
  LAST_MILE_DELIVERY = 'LAST_MILE_DELIVERY',
}
