import React, { useState } from 'react';
import {
  Col, Grid, Nav, Panel, Row,
} from 'rsuite';
import { updatePrices, updateStocks } from '../api/products';
import FileUploadCsv from '../components/FileUploadCsv';
import { importB2bPrices } from '../api/b2b/b2b';

enum Tab {
  STOCK = 'STOCK',
  PRICE = 'PRICE',
  B2B_PRICE = 'B2B_PRICE',
}

function getAccept(tab: Tab): '.xlsx' | '.csv' {
  if (tab === Tab.B2B_PRICE) {
    return '.xlsx';
  }

  return '.csv';
}

export default function UpdateStocksAndPrices() {
  const [activeTab, setActiveTab] = useState<Tab>(Tab.STOCK);
  const accept = getAccept(activeTab);

  async function uploadFile(file: Blob) {
    const data = new FormData();
    data.append('attachment', file);

    switch (activeTab) {
      case Tab.STOCK: {
        const response = await updateStocks(data);
        return response.data;
      }
      case Tab.PRICE: {
        const response = await updatePrices(data);
        return response.data;
      }
      case Tab.B2B_PRICE: {
        const response = await importB2bPrices(data)
          .then(() => ({ data: {} }));
        return response.data;
      }
      default:
        return undefined;
    }
  }

  return (
    <Panel>
      <Grid fluid>
        <Row>
          <Col xs={24}>
            <Nav
              appearance="subtle"
              activeKey={activeTab}
              onSelect={(tab: Tab) => setActiveTab(tab)}
            >
              <Nav.Item eventKey={Tab.STOCK}>Stock</Nav.Item>
              <Nav.Item eventKey={Tab.PRICE}>Prices</Nav.Item>
              <Nav.Item eventKey={Tab.B2B_PRICE}>B2B prices</Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <FileUploadCsv
              className="mt-5"
              getUploadResult={(file) => uploadFile(file)}
              accept={[accept]}
            />
          </Col>
        </Row>
      </Grid>
    </Panel>
  );
}
