import React, { useEffect, useReducer, useRef } from 'react';
import { Stack } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import { ApiGroup } from '../../../api/model/ApiGroup';
import { Carrier, EshopCarrier, MpiDeliveryType } from '../../../api/apiTypes';
import CarrierSetupAlzaEditForm from './CarrierSetupAlzaEditForm';
import { getCarriers } from '../../../api/carrier';
import { utils } from '../../../utils/utils';
import { showSuccessMessage } from '../../../utils/message';
import { useCarrierSetupEditContext } from '../CarrierSetupEditContext';

interface State {
  eshopCarrier?: EshopCarrier;
  carriers: Carrier[];
  edit: Edit;
  status: 'idle' | 'loading';
}

interface Edit {
  eshopCode?: string;
  eshopCarrierCode?: string;
  eshopCarrierType?: string;
  name?: string;
  carrierId?: string;
  deliveryType?: string;
  portalName?: string;
}

const initialEdit: Edit = {
  eshopCode: '',
  eshopCarrierCode: '',
  eshopCarrierType: '',
  name: '',
  carrierId: '',
  deliveryType: '',
  portalName: '',
};

const initialState: State = {
  eshopCarrier: undefined,
  carriers: [],
  edit: initialEdit,
  status: 'idle',
};

type CarriersLoadAction = { type: 'carriers/load' };
type CarriersSetAction = { type: 'carriers/set', payload: Carrier[] };
type EditSetAction = { type: 'edit/set', payload: Edit };
type Action = CarriersLoadAction
| EditSetAction
| CarriersSetAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'carriers/load':
      return { ...state, status: 'loading' };
    case 'carriers/set':
      return { ...state, status: 'idle', carriers: action.payload };
    case 'edit/set':
      return { ...state, edit: action.payload };
    default:
      return state;
  }
}

function eshopCarrierToEdit(eshopCarrier?: EshopCarrier): Edit {
  if (!eshopCarrier) {
    return initialEdit;
  }

  return {
    eshopCode: utils.defaultToEmpty(eshopCarrier.eshopCode),
    eshopCarrierCode: utils.defaultToEmpty(eshopCarrier.eshopCarrierCode),
    eshopCarrierType: utils.defaultToEmpty(eshopCarrier.eshopCarrierType),
    name: utils.defaultToEmpty(eshopCarrier.name),
    carrierId: utils.defaultToEmpty(String(eshopCarrier.carrierId)),
    deliveryType: utils.defaultToEmpty(eshopCarrier.defaultMpiDeliveryType),
    portalName: utils.defaultToEmpty(eshopCarrier.portalName),
  };
}

function editToEshopCarrier(edit: Edit, eshopCarrier?: EshopCarrier): Partial<EshopCarrier> {
  return {
    ...eshopCarrier,
    eshopCode: edit.eshopCode,
    eshopCarrierCode: edit.eshopCarrierCode,
    eshopCarrierType: edit.eshopCarrierType,
    name: edit.name,
    carrierId: Number(edit.carrierId),
    defaultMpiDeliveryType: MpiDeliveryType[edit.deliveryType as keyof typeof MpiDeliveryType],
    portalName: edit.portalName,
  };
}

export default function CarrierSetupAlzaEdit() {
  const {
    apiGroup,
    eshopCarrier,
    eshops,
    isSaving,
    saveEshopCarrier,
  } = useCarrierSetupEditContext();
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const abortControllerRef = useRef(new AbortController());

  const filteredEshops = eshops.filter((eshop) => eshop.apiGroup === ApiGroup.ALZA);
  const mappedCarriers = state.carriers.map((carrier) => ({ ...carrier, id: String(carrier.id) }));

  async function fetchCarriers(signal: AbortSignal): Promise<void> {
    dispatch({ type: 'carriers/load' });
    const response = await getCarriers(signal);
    dispatch({ type: 'carriers/set', payload: response });
  }

  async function save(): Promise<void> {
    if (abortControllerRef.current.signal.aborted) {
      abortControllerRef.current = new AbortController();
    }

    const editedEshopCarrier = editToEshopCarrier(state.edit, eshopCarrier);
    await saveEshopCarrier(editedEshopCarrier, abortControllerRef.current.signal);
    showSuccessMessage('Carrier mapping successfully saved.');
  }

  useEffect(() => {
    const payload = eshopCarrierToEdit(eshopCarrier);
    dispatch({ type: 'edit/set', payload });
  }, [eshopCarrier]);

  useEffect(() => {
    const abortController = new AbortController();

    fetchCarriers(abortController.signal).then();

    return () => {
      abortController.abort();
    };
  }, []);

  function handleChange(edit: Edit): void {
    dispatch({ type: 'edit/set', payload: edit });
  }

  function handleSave(): void {
    save().then();
  }

  function handleCancel(): void {
    navigate(-1);
  }

  return (
    <Stack direction="column" alignItems="stretch" spacing={15} justifyContent="flex-start">
      <h4>
        {apiGroup}
        {' '}
        { eshopCarrier ? '(edit carrier mapping)' : '(new carrier mapping)'}
      </h4>
      <CarrierSetupAlzaEditForm
        eshops={filteredEshops}
        carriers={mappedCarriers}
        edit={state.edit}
        isLoading={state.status === 'loading'}
        isSaving={isSaving}
        onChange={(value) => handleChange(value)}
        onSave={() => handleSave()}
        onCancel={() => handleCancel()}
      />
    </Stack>
  );
}

CarrierSetupAlzaEdit.defaultProps = {
  eshopCarrier: undefined,
};
