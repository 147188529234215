import { Address } from '../address/addressApiTypes';

export interface B2bAddress extends Omit<Address, 'state' | 'zip' | 'country' | 'note'> {
  stateCode?: string;
  zipCode?: string;
  countryCode?: string;
}

export function mapAddress(b2bAddress: B2bAddress): Address {
  const { stateCode: state, zipCode: zip, countryCode: country } = b2bAddress;

  return {
    ...{
      ...b2bAddress,
      stateCode: undefined,
      zipCode: undefined,
      countryCode: undefined,
    },
    state,
    zip,
    country,
  };
}

export function mapB2bAddress(address: Omit<Address, 'id'>): Omit<B2bAddress, 'id'> {
  const { state: stateCode, zip: zipCode, country: countryCode } = address;

  return {
    ...{
      ...address,
      state: undefined,
      zip: undefined,
      country: undefined,
    },
    stateCode,
    zipCode,
    countryCode,
  };
}
