import { B2bAddress } from './b2bApiTypes';

export const mockGetAddress: B2bAddress = {
  id: 1,
  addressLine1: 'U garáží 1',
  city: 'Wroclaw',
  countryCode: 'PL',
  email: 'info@mall.pl',
  name: 'Józef Nowak',
  phone: '+48123456789',
  zipCode: '50-155',
};

export const mockCreateOrUpdateAddress: B2bAddress = {
  id: 1,
  addressLine1: 'U garáží 1',
  city: 'Wroclaw',
  countryCode: 'PL',
  email: 'info@mall.pl',
  name: 'Józef Nowak',
  phone: '+48123456789',
  zipCode: '50-155',
};
