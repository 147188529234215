import React, { useEffect, useRef, useState } from 'react';
import {
  Button, ButtonToolbar, Form, FormInstance,
} from 'rsuite';
import _ from 'lodash';
import { Address } from '../../api/address/addressApiTypes';

interface Props {
  address?: Address;
  isSubmitting: boolean;
  editingKeys?: string[];
  model?: any,
  layout?: 'vertical' | 'horizontal' | 'inline';
  onSubmit?: (address: Address) => void;
  onCancel?: () => void;
}

interface State {
  edit: Edit;
}

type Edit = {
  [key in keyof Omit<Required<Address>, 'id' | 'originalId'>]: string;
};

const initialEdit: Edit = {
  name: '',
  company: '',
  phone: '',
  email: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  note: '',
  registrationNumber: '',
  vatNumber: '',
};

export default function AddressForm(
  {
    address, isSubmitting, editingKeys, model, layout, onSubmit, onCancel,
  }: Props,
): JSX.Element {
  const [state, setState] = useState<State>({ edit: _.defaults(address, initialEdit) });
  const formRef = useRef<FormInstance>(null);

  useEffect(() => {
    setState({ edit: _.defaults(address, initialEdit) });
  }, [address]);

  function handleChange(edit: Record<string, any>) {
    setState({ edit: edit as Edit });
  }

  function handleCancel() {
    setState({ edit: _.defaults(address, initialEdit) });
    onCancel?.();
  }

  function handleSubmit() {
    if (model && !formRef.current?.check()) {
      return;
    }

    onSubmit?.(state.edit as Address);
  }

  function isHidden(key: string): boolean {
    return !(editingKeys ?? []).includes(key);
  }

  return (
    <Form
      layout={layout}
      formValue={state.edit}
      onChange={(edit) => handleChange(edit)}
      model={model}
      ref={formRef}
    >
      <Form.Group hidden={isHidden('name')}>
        <Form.ControlLabel>
          Name
        </Form.ControlLabel>
        <Form.Control
          name="name"
          type="text"
        />
      </Form.Group>
      <Form.Group hidden={isHidden('addressLine1')}>
        <Form.ControlLabel>
          Street 1
        </Form.ControlLabel>
        <Form.Control
          name="addressLine1"
          type="text"
        />
      </Form.Group>
      <Form.Group hidden={isHidden('addressLine2')}>
        <Form.ControlLabel>
          Street 2
        </Form.ControlLabel>
        <Form.Control
          name="addressLine2"
          type="text"
        />
      </Form.Group>
      <Form.Group hidden={isHidden('city')}>
        <Form.ControlLabel>
          City
        </Form.ControlLabel>
        <Form.Control
          name="city"
          type="text"
        />
      </Form.Group>
      <Form.Group hidden={isHidden('zip')}>
        <Form.ControlLabel>
          Zip code
        </Form.ControlLabel>
        <Form.Control
          name="zip"
          type="text"
        />
      </Form.Group>
      <Form.Group hidden={isHidden('company')}>
        <Form.ControlLabel>
          Company
        </Form.ControlLabel>
        <Form.Control
          name="company"
          type="text"
        />
      </Form.Group>
      <Form.Group hidden={isHidden('phone')}>
        <Form.ControlLabel>
          Phone
        </Form.ControlLabel>
        <Form.Control
          name="phone"
          type="text"
        />
      </Form.Group>
      <Form.Group hidden={isHidden('email')}>
        <Form.ControlLabel>
          E-mail
        </Form.ControlLabel>
        <Form.Control
          name="email"
          type="text"
        />
      </Form.Group>
      <Form.Group hidden={isHidden('state')}>
        <Form.ControlLabel>
          State
        </Form.ControlLabel>
        <Form.Control
          name="state"
          type="text"
        />
      </Form.Group>
      <Form.Group hidden={isHidden('country')}>
        <Form.ControlLabel>
          Country
        </Form.ControlLabel>
        <Form.Control
          name="country"
          type="text"
        />
      </Form.Group>
      <Form.Group hidden={isHidden('note')}>
        <Form.ControlLabel>
          Note
        </Form.ControlLabel>
        <Form.Control
          name="note"
          type="text"
        />
      </Form.Group>
      <Form.Group hidden={isHidden('registrationNumber')}>
        <Form.ControlLabel>
          Registration number
        </Form.ControlLabel>
        <Form.Control
          name="registrationNumber"
          type="text"
        />
      </Form.Group>
      <Form.Group hidden={isHidden('vatNumber')}>
        <Form.ControlLabel>
          VAT number
        </Form.ControlLabel>
        <Form.Control
          name="vatNumber"
          type="text"
        />
      </Form.Group>
      <Form.Group>
        <ButtonToolbar>
          <Button
            appearance="ghost"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
          <Button
            appearance="primary"
            onClick={() => handleSubmit()}
            type="submit"
            loading={isSubmitting}
          >
            Save
          </Button>
        </ButtonToolbar>
      </Form.Group>
    </Form>
  );
}

AddressForm.defaultProps = {
  address: undefined,
  editingKeys: [
    'addressLine1',
    'addressLine2',
    'city',
    'zip',
    'company',
    'phone',
    'email',
  ],
  model: undefined,
  layout: 'vertical',
  onSubmit: undefined,
  onCancel: undefined,
};
