import React, { useReducer } from 'react';
import { Button, Message, Stack } from 'rsuite';
import { AllegroLinkingInfo } from '../../../../api/apiTypes';
import { useStoreState } from '../../../../store/hooks';
import { postAllegroUnlinkAccount } from '../../../../api/user';
import { showSuccessMessage } from '../../../../utils/message';
import Alert from '../../../Alert';

interface Props {
  linkingInfo: AllegroLinkingInfo;
  onSuccessfulUnlink: () => void;
}

interface State {
  isUnlinking: boolean;
  isAlertOpen: boolean;
}

type IsUnlinkingSetAction = { type: 'isUnlinking/set', payload: boolean };
type IsAlertOpenSetAction = { type: 'isAlertOpen/set', payload: boolean };
type ConfirmUnlinkAction = { type: 'confirmUnlink' };
type Action = IsUnlinkingSetAction | IsAlertOpenSetAction | ConfirmUnlinkAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'isUnlinking/set':
      return { ...state, isUnlinking: action.payload };
    case 'isAlertOpen/set':
      return { ...state, isAlertOpen: action.payload };
    case 'confirmUnlink':
      return { ...state, isUnlinking: true, isAlertOpen: false };
    default:
      return state;
  }
}

const initialState: State = {
  isUnlinking: false,
  isAlertOpen: false,
};

export default function EshopSetupAllegroCredentialsLinked(
  { linkingInfo, onSuccessfulUnlink }: Props,
) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isUnlinking, isAlertOpen } = state;
  const { currentUser } = useStoreState(storeState => storeState.user);

  const hasError = !!linkingInfo.errorMessage;

  async function unlink() {
    const { partnerCode } = currentUser;
    const { eshopCode } = linkingInfo;

    try {
      await postAllegroUnlinkAccount(partnerCode, eshopCode);
      showSuccessMessage('Account was successfully unlinked.');
      onSuccessfulUnlink();
    } finally {
      dispatch({ type: 'isUnlinking/set', payload: false });
    }
  }

  function handleUnlink() {
    dispatch({ type: 'isAlertOpen/set', payload: true });
  }

  function handleConfirm() {
    dispatch({ type: 'confirmUnlink' });
    unlink().then();
  }

  function handleClose() {
    dispatch({ type: 'isAlertOpen/set', payload: false });
  }

  return (
    <>
      <Stack spacing={20}>
        <Message type="success">
          <div style={{ fontWeight: 'bold' }}>{`Your account is linked to ${linkingInfo.eshopCode}`}</div>
          {hasError && <div>{`Error: ${linkingInfo?.errorMessage}`}</div>}
          {!hasError && (
            <>
              <div>{`login: ${linkingInfo.allegroUser?.login} (${linkingInfo?.allegroUser?.id})`}</div>
              <div>{`base marketplace: ${linkingInfo.allegroUser?.baseMarketplace.id}`}</div>
            </>
          )}
        </Message>
        <Button
          appearance="primary"
          color="red"
          onClick={() => handleUnlink()}
          loading={isUnlinking}
        >
          Unlink
        </Button>
      </Stack>
      <Alert
        isOpen={isAlertOpen}
        isLoading={isUnlinking}
        onConfirm={() => handleConfirm()}
        onClose={() => handleClose()}
        title="Confirmation"
      >
        <p>
          Do you really want to
          {' '}
          <strong>unlink</strong>
          {' '}
          your account ?
        </p>
      </Alert>
    </>
  );
}
