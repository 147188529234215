import { Message, toaster } from 'rsuite';
import React from 'react';

export function showSuccessMessage(message: string) {
  toaster.push(
    <Message
      type="success"
      closable
      showIcon
      duration={2000}
    >
      {message}
    </Message>,
  );
}

export function showErrorMessage(message: string) {
  toaster.push(
    <Message
      type="error"
      closable
      showIcon
      duration={2000}
    >
      {message}
    </Message>,
  );
}
