import React from 'react';
import IframeResizer from 'iframe-resizer-react';
import styled from '@emotion/styled';
import { getCurrentUserToken } from '../services/authorization';
import { MORPHER_URL } from '../api/base';

const StyledIframeResizer = styled(IframeResizer)`
  min-width: 100%;
  min-height: 100vh;
  border: none;
`;

export default function FeedMorpher() {
  const currentUserToken = getCurrentUserToken();
  const src = `${MORPHER_URL}?userToken=${currentUserToken}`;

  return (
    <StyledIframeResizer
      src={src}
      heightCalculationMethod="lowestElement"
      scrolling
      inPageLinks
    />
  );
}
