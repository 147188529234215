import React, { useEffect, useReducer, useRef } from 'react';
import { Address, AddressType } from '../../api/address/addressApiTypes';
import { getAddress, updateAddress } from '../../api/address/address';
import { showSuccessMessage } from '../../utils/message';
import AddressForm from './AddressForm';

interface Props {
  orderId: number;
  addressType: AddressType;
  onCancel?: () => void;
}

type Status = 'ready' | 'loading' | 'saving';

interface State {
  status: Status,
  address?: Address
}

const initialState: State = {
  status: 'ready',
};

type StatusSetAction = { type: 'status/set', payload: Status };
type AddressSetAction = { type: 'address/set', payload: Address };
type Action = StatusSetAction | AddressSetAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'status/set': {
      return { ...state, status: action.payload };
    }
    case 'address/set': {
      return {
        ...state,
        address: action.payload,
      };
    }
    default:
      return state;
  }
}

export default function OrderAddressEdit({
  orderId,
  addressType,
  onCancel,
}: Props): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState);
  const abortControllerRef = useRef<AbortController>(new AbortController());

  async function fetchAddress() {
    if (abortControllerRef.current.signal.aborted) {
      abortControllerRef.current = new AbortController();
    }

    dispatch({ type: 'status/set', payload: 'loading' });

    try {
      const address = await getAddress(orderId, addressType, abortControllerRef.current.signal);
      dispatch({ type: 'address/set', payload: address });
    } finally {
      dispatch({ type: 'status/set', payload: 'ready' });
    }
  }

  async function saveAddress(address: Address) {
    dispatch({ type: 'status/set', payload: 'saving' });

    try {
      await updateAddress(orderId, addressType, address, abortControllerRef.current.signal);
      showSuccessMessage('Address successfully updated.');
    } finally {
      dispatch({ type: 'status/set', payload: 'ready' });
    }
  }

  useEffect(() => {
    fetchAddress().then();

    return () => {
      abortControllerRef.current?.abort();
    };
  }, [orderId, addressType]);

  function handleSubmit(address: Address) {
    saveAddress(address).then();
  }

  function handleCancel() {
    onCancel?.();
  }

  return (
    <AddressForm
      address={state.address}
      isSubmitting={state.status === 'saving'}
      onSubmit={(address) => handleSubmit(address)}
      onCancel={() => handleCancel()}
    />
  );
}

OrderAddressEdit.defaultProps = {
  onCancel: undefined,
};
